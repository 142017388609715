import React from 'react';

const Header = () => (
  <div className="header">
    <h1>House</h1>
    <h2>Your AI Chef Assistant</h2>
  </div>
);

export default Header;
